document.querySelector('.bg-video').playbackRate = 0.6

document.querySelectorAll('[show-url]').forEach(elm => {
  let showUrl = elm.getAttribute('show-url')
  let showTitle = elm.getAttribute('show-title')
  let showStreamTime = elm.getAttribute('show-stream-time')
  tippy(elm, {
    content: `<a href='${showUrl}' target='_blank'>Visit ${showTitle}</a><br>or</br><a href="https://www.youtube.com/watch?v=1lftzQKDQ6c" target='_blank'>Join Livestream<br>(${showStreamTime})</a>`,
    interactive: true,
    allowHTML: true,
    placement: 'top-start'
  });
})